import React from "react"

const PolicyMenu: React.FC<{
  titleLinkComboList: {
    title: string
    linkId: string
    subMenu: { title: string; linkId: string }[]
  }[]
  scrollToElement: (id: string) => void
}> = ({ titleLinkComboList, scrollToElement }) => {
  return (
    <div className="policy-menu-inner">
      <ul>
        {titleLinkComboList.map((content, i) => (
          <li key={i} style={{ marginBottom: 40 }}>
            <a
              href={`#${content.linkId}`}
              onClick={e => {
                e.preventDefault()
                scrollToElement(content.linkId)
              }}
            >
              {content.title}
            </a>
            {content.subMenu.length > 0 && (
              <ul>
                {content.subMenu.map((subContent, j) => (
                  <li key={j}>
                    <a
                      href={`#${subContent.linkId}`}
                      onClick={e => {
                        e.preventDefault()
                        scrollToElement(subContent.linkId)
                      }}
                    >
                      {subContent.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PolicyMenu
