const headerHeight = 300
export const scrollToElement = (
  id: string,
  contentRef: React.RefObject<HTMLDivElement>
) => {
  const element = document.getElementById(id)
  if (element && contentRef.current) {
    const elementPosition = element.offsetTop
    const offsetPosition = elementPosition - headerHeight

    contentRef.current.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    })
  }
}
