import React from "react"
import { WindowLocation } from "@reach/router"
import Header from "./utils/Header"
import CookieConsent from "react-cookie-consent"
import "../components/cookieStyle.css"
import { headerMenu } from "./const"
import { Link } from "gatsby"

type Props = {
  selectPageName: string
  children: React.ReactNode
}

const Layout: React.FC<Props & { location: WindowLocation<unknown> }> = ({
  selectPageName,
  location,
  children
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const isEnPage = !!location.pathname && location.pathname.startsWith("/en")

  return (
    <div className="layout-background-color">
      <div className={"util-full-page-padding"}>
        <div className="over-width-layout">
          <div data-is-root-path={isRootPath} className={"layout-wrapper"}>
            <div className={"fade-in-transition"}>
              <Header headerMenu={headerMenu} selectPageName={selectPageName} />
              <main>{children}</main>
            </div>
          </div>
        </div>
      </div>
      <footer className={"footer"}>
        <div className={"footer-content-wrapper"}>
          <div className={"footer-text-wrapper"}>
            <p className={"footer-logo"}>©</p>
            <p>2021 D Capital, Inc.</p>
          </div>
          <div className={"footer-text-wrapper"}>
            <Link
              style={{
                textDecoration: "none",
                color: "#c2c2c2",
                fontSize: "9pt"
              }}
              to={isEnPage ? "/en/policy" : "/policy"}
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
      <CookieConsent
        location="bottom" // cookie bannerの配置
        style={{ backgroundColor: "white" }}
        enableDeclineButton // 同意しない場合のボタン追加
        buttonText={
          <div>
            同意する
            <br />
            Accept
          </div>
        }
        declineButtonText={
          <div>
            同意しない
            <br />
            Decline
          </div>
        }
        onAccept={() => window.location.reload()} // 同意したあとの画面更新
        buttonClasses="accept-button"
        declineButtonClasses="decline-button"
        containerClasses="cookie-contents-wrapper"
        buttonWrapperClasses="buttons-wrapper"
        contentClasses="cookie-text-wrapper"
        cookieName="gatsby-gdpr-google-analytics"
      >
        <div
          className="cookie-text"
          style={{ color: "black", textAlign: "center" }}
        >
          <div>
            本サイトは機能実現・改善のためにクッキー(Cookie)を使用します。
          </div>
          <div>By browsing this site, you agree to the use of cookies.</div>
        </div>
      </CookieConsent>
    </div>
  )
}

export default Layout
