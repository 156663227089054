import React from "react"
import { PolicyContentArray } from "./type"

const PolicyContent: React.FC<{
  contents: PolicyContentArray
  lang: "ja" | "en"
}> = ({ contents, lang }) => {
  if (!contents.length) {
    return null
  }

  return (
    <div className="policy-content-inner">
      {contents.map((content, i) => (
        <div
          key={i}
          className="policy-item"
          id={content.link_id}
          style={{ marginBottom: 100 }}
        >
          {content.contents.map((item, j) => (
            <div
              key={j}
              className="policy-sub-item"
              id={item.contentId}
              style={{ marginBottom: 100 }}
            >
              <div
                className={`policy-rich-text-${lang}`}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </div>
          ))}
          {i < contents.length - 1 && (
            <hr style={{ width: "100%", borderColor: "#c2c2c2" }} />
          )}
        </div>
      ))}
    </div>
  )
}

export default PolicyContent
