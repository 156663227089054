import { Query } from "../../../types/graphql-types"
import { PolicyContentArray } from "./type"

const transformPolicyContents = (
  edges:
    | Query["allMicrocmsPolicy"]["edges"]
    | Query["allMicrocmsPolicyEn"]["edges"]
): PolicyContentArray => {
  return edges.flatMap(
    ({ node }) =>
      node.contents?.map(content => ({
        title: content?.title || "",
        contents:
          content?.contents?.map(item => ({
            content: item?.content || "",
            contentId: item?.content_id || "",
            subTitle: item?.sub_title || ""
          })) || [],
        link_id: content?.link_id || ""
      })) || []
  )
}

export const transformPolicyData = (
  allMicrocmsPolicy: Query["allMicrocmsPolicy"]
): PolicyContentArray => {
  return transformPolicyContents(allMicrocmsPolicy.edges)
}

export const transformPolicyEnData = (
  allMicrocmsPolicyEn: Query["allMicrocmsPolicyEn"]
): PolicyContentArray => {
  return transformPolicyContents(allMicrocmsPolicyEn.edges)
}

export const createTitleLinkComboList = (
  policyContents: PolicyContentArray
) => {
  return policyContents.map(content => ({
    title: content.title,
    linkId: content.link_id,
    subMenu: content.contents.map(item => ({
      title: item.subTitle,
      linkId: item.contentId
    }))
  }))
}
