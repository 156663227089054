import { useEffect } from "react"

const useCustomScroll = (contentRef: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (contentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current
        const isAtBottom = scrollTop + clientHeight >= scrollHeight
        const isAtTop = scrollTop === 0

        if (e.deltaY > 0 && !isAtBottom) {
          contentRef.current.scrollTop += e.deltaY
          e.preventDefault()
        } else if (e.deltaY < 0 && !isAtTop) {
          contentRef.current.scrollTop += e.deltaY
          e.preventDefault()
        }
      }
    }

    window.addEventListener("wheel", handleWheel, { passive: false })
    return () => window.removeEventListener("wheel", handleWheel)
  }, [contentRef])
}

export default useCustomScroll
